import React from "react"
import * as cn from "classnames"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import * as styles from "./button.module.scss"

export const ButtonAnchor = ({ href, children }) => (
  <AnchorLink to={href} className={cn(styles.butn, styles.butnBg)}>
    {children}
  </AnchorLink>
)

export const Button = ({ onCLick, children, ...rest }) => (
  <button
    onClick={onCLick}
    className={cn(styles.butn, styles.butnBg)}
    {...rest}
  >
    {children}
  </button>
)
